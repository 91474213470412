
import {defineComponent} from "vue";
import supplier from "@/services/supplier";
import priceImport from "@/services/priceImport";
import routerService from "@/services/routerService";
import Spinner from "@/components/Spinner.vue";
import PriceLoadModal from "@/components/modals/PriceLoadModal.vue";
import PricesSettings from "@/components/prices-list/PricesSettings.vue";
import prices from '@/services/prices';
import BaseTable from '@/components/ui/BaseTable.vue';
import timeService from '@/services/timeService';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import { SupplierType } from "@/components/types/enums";
import DetailPricesComparisonTableWrapper from "@/components/prices-list/DetailPricesComparisonTableWrapper.vue"
import AddSupplierBrandOeCmModal from '@/components/modals/AddSupplierBrandOeCmModal.vue';
import DoughnutChartView from '@/plugins/Chart/DoughnutChartView.vue';
import DropdownActions from '@/components/article-card/history/DropdownActions.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import HistoryPrices from '@/components/prices-list/HistoryPrices.vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import BaseError from '@/components/ui/BaseError.vue';
import BaseToggleButtons from '@/components/ui/BaseToggleButtons.vue';

export default defineComponent({
  name: 'PricesList',
  components: {
    BaseError,
    BaseTabs,
    DoughnutChartView,
    BaseTable,
    Spinner,
    PriceLoadModal,
    PricesSettings,
    SmallSpinner,
    BaseIcon,
    DetailPricesComparisonTableWrapper,
    AddSupplierBrandOeCmModal,
    DropdownActions,
    BaseCheckbox,
    HistoryPrices,
    BaseToggleButtons
  },
  data: () => ({
    errorPriceExist: '' as string,
    articleOfAllBrandsForHistoryChart: null as any,
    loadingDownloadGeneralPricesReport: false as any,
    selectedBrandsForComparisonTable: null as any,
    articlesForDetailTable: null as any,
    pricesGeneralReport: null as any,
    reference: null as any,
    allSuppliers: null as any,
    pickedProductGroups: [] as any,
    pickedArticles: [] as any,
    pickedBrands: [] as any,
    isLoading: true as any,
    loadingDownloadPricesComparison: false as boolean,
    subMenuTabs: [
      { name: 'Общий отчет', query: 'groups' }
    ] as Array<{name: string, query: string}>,
    activeMenu: 'groups' as any,
    currentSupplierAndBrands: null as any,
    allTabs: {
      history: { name: 'История', query: 'history' },
      detail: { name: 'Детальный отчет', query: 'detail' },
      groups: { name: 'Общий отчет', query: 'groups' }
    } as any
  }),
  computed: {
    generalReportTableHeader(): Array<{title: string, content: Function}> {
      return [
        {
          title: this.reference.name,
          content: (contentItem: {name: string, field: string, reason: string}) => contentItem.name,
        },
        ...Object
          .keys(this.pricesGeneralReport.compareInfo)
          .map((comparisonBrand: string) => {
            return {
              title: comparisonBrand,
              content: (contentItem: {name: string, field: string, reason: string}) => {
                return this.pricesGeneralReport.compareInfo[comparisonBrand][contentItem.field]
              }
            }
          }
        )
      ]
    },
    isShowExportDataByRole(): boolean {
      return this.$store.getters.getAllPermissions.upload_price[this.$store.getters.getUserRole]
    },
    isSelectedSupplier() {
      return (type: any) => this.pickedBrands.filter((brand: any) => brand.id === type.id).length
    },
    getGeneralReportContentItems(): Array<{field: string, reason: string, name: string}> {
      return [
        {
          field: 'comparedSize',
          reason: 'COMPARED',
          name: 'Найдено совпадений'
        },
        {
          field: 'noArticlesStandard',
          reason: 'STANDARD_ARTICLE_NOT_FOUND',
          name: `Нет артикула у ${this.reference.name}`,
        },
        {
          field: 'noArticlesBrand',
          reason: 'BRAND_ARTICLE_NOT_FOUND',
          name: 'Нет артикула у бренда',
        },
        {
          field: 'noPricesBrand',
          reason: 'BRAND_PRICES_NOT_FOUND',
          name: 'Нет цены у бренда'
        },
      ]
    }
  },
  methods: {
    setHistory(articlesOfAllBrands: Array<any>) {
      const isExistTab = this.subMenuTabs.findIndex((tab: any) => tab.query === 'history') >= 0

      if (!isExistTab) {
        this.subMenuTabs.push({query: 'history', name: 'История'})
      }

      this.setViewTable('subContent', 'history')
      this.articleOfAllBrandsForHistoryChart = articlesOfAllBrands
    },
    async setViewTable(queryName: string, is: any) {
      await routerService.setQuery(queryName, is, this.$route.query, this.$router)
    },
    deleteSelectedBrand(pickedBrand: any) {
      this.pickedBrands = this.pickedBrands.filter((brand: any) => brand.id !== pickedBrand.id)
    },
    setDetailReport(text: string, brand: string) {
      const reportContentItem = this.getGeneralReportContentItems.find((report: any) => {
        return report.name === text
      }) as any

      this.getInfoForDetailReportByChart(reportContentItem.reason, brand)
    },
    async downloadGeneralPricesReport() {
      this.loadingDownloadGeneralPricesReport = true

      let table = await prices.toExcel({
        standardId: this.reference.id,
        brandIds: this.pickedBrands && this.pickedBrands.length ? [
          ...this.pickedBrands
              .map((brand: any) => brand.id)
              .sort(function(a: number, b: number) {
                return a - b
           })
        ] : []
      }) as any;

      let date = timeService.createDateForViewFromUTCTime();

      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', `Prices_general_report_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.loadingDownloadGeneralPricesReport = false
    },
    async getComparedByAllBrands() {
      const sortedBrands = this.pickedBrands && this.pickedBrands.length ? [
          ...this.pickedBrands
            .sort(function(a: number, b: number) {
              return a - b
           })
        ] : []

      this.isLoading = true

      const isExistTab = this.subMenuTabs.findIndex((tab: any) => tab.query === 'detail') >= 0

      if (!isExistTab) {
        this.subMenuTabs.push(this.allTabs.detail)
        await this.setViewTable('subContent', 'detail')
      }

      this.articlesForDetailTable = this.pricesGeneralReport.compared
      console.log('THis article detail table', this.articlesForDetailTable)
      this.selectedBrandsForComparisonTable = [
        this.reference,
        ...sortedBrands
      ]

      this.currentSupplierAndBrands = {
        supplier: this.reference,
        brands: sortedBrands
      }

      this.isLoading = false
      await this.setViewTable('subContent', 'detail')
    },
    async getInfoForDetailReportByChart(reason: any, pickedBrandName: string) {
      const indexBrand = this.pickedBrands.findIndex((brand: any) => {
        return pickedBrandName === brand.name
      })
      this.selectedBrandsForComparisonTable = [
        this.reference,
        this.pickedBrands[indexBrand]
      ]

      const isExistTab = this.subMenuTabs.findIndex((tab: any) => tab.query === 'detail') >= 0

      if (!isExistTab) {
        this.subMenuTabs.push(this.allTabs.detail)
      }

      this.isLoading = true
      if (reason === 'COMPARED') {
        //бек сразу присылает данные сравнений по compared, забираем для каждого бренда свой
        this.articlesForDetailTable = this.pricesGeneralReport.compareInfo[this.pickedBrands[indexBrand].name].compared
      } else {
        this.articlesForDetailTable = await priceImport.getPriceAnalysesDetails({
          to: this.pickedBrands[indexBrand].id,
          from: this.reference.id,
          reason
        }) as any
      }

      this.currentSupplierAndBrands = {
        supplier: this.reference,
        brands: [this.pickedBrands[indexBrand]]
      }

      this.isLoading = false
      await this.setViewTable('subContent', 'detail')
    },
    async getInfoForDetailReport(reason: string, columnIndex: number) {
      const indexBrand = this.pickedBrands.findIndex((brand: any) => {
        return this.generalReportTableHeader[columnIndex].title === brand.name
      })

      this.selectedBrandsForComparisonTable = [
        this.reference,
        this.pickedBrands[indexBrand]
      ]

      this.isLoading = true

      const isExistTab = this.subMenuTabs.findIndex((tab: any) => tab.query === 'detail') >= 0

      if (!isExistTab) {
        this.subMenuTabs.push(this.allTabs.detail)
      }

      await this.setViewTable('subContent', this.allTabs.detail.query)

      if (reason === 'COMPARED') {
        //бек сразу присылает данные сравнений по compared, забираем для каждого бренда свой
        this.articlesForDetailTable = this.pricesGeneralReport.compareInfo[this.pickedBrands[indexBrand].name].compared
      } else {
        try {
          this.articlesForDetailTable = await priceImport.getPriceAnalysesDetails({
            to: this.pickedBrands[indexBrand].id,
            from: this.reference.id,
            reason
          }) as any
        } catch (e) {}
      }

      this.currentSupplierAndBrands = {
        supplier: this.reference,
        brands: [this.pickedBrands[indexBrand]]
      }

      this.isLoading = false
    },
    openAddBrandModal() {
      let element = document.getElementById(`modal-add-supplier-brand`)!
      element.style.display = 'flex'
    },
    openUploadPriceModal() {
      let element = document.getElementById(`modal-price-load`)!
      element.style.display = 'flex'
    },
    setTypeBrand(type: any) {
      this.pricesGeneralReport = null

      const findBrand = this.pickedBrands.findIndex((brand: any) => type.id === brand.id)
      if (findBrand !== -1) {
        this.pickedBrands.splice(findBrand, 1)
      } else {
        this.pickedBrands.push(type)
      }
    },
    async getInfoPrice() {
      this.subMenuTabs = [this.allTabs.groups]
      await routerService.clearQueries(
        ['subContent', 'viewGeneralReport', 'showTable'],
        this.$route.query,
        this.$router
      )

      this.pricesGeneralReport = null;
      this.isLoading = true;

      await routerService.setQuery('viewGeneralReport', 'table', this.$route.query, this.$router)

      this.pricesGeneralReport = await priceImport.getPricesAnalyses({
        standardId: this.reference.id,
        brandIds: this.pickedBrands && this.pickedBrands.length ? [
          ...this.pickedBrands
            .map((brand: any) => brand.id)
            .sort(function(a: number, b: number) {
              return a - b
            })
        ] : []
      })

      this.isLoading = false
    },
    async setSuppliers() {
      this.allSuppliers = {
        manufacturerBrands: await supplier.getAllByType(SupplierType.MANUFACTURER),
        distributorBrands: await supplier.getAllByType(SupplierType.DISTRIBUTOR)
      }
    }
  },
  async mounted() {
    await this.setSuppliers()

    if (!this.$route.query.articleId) { // если есть articleId, тогда не очищаем query, потому что переход был из карточки артикула
      await routerService.clearQueries(
        ['subContent', 'viewGeneralReport', 'showTable'],
        this.$route.query,
        this.$router
      )
    } else {
      try {
        const response = await priceImport.analysesByArticle(this.$route.query.articleId)
        this.subMenuTabs = [{query: 'detail', name: 'Детальный отчет'}]
        await this.setViewTable('content', 'full-prices')
        await this.setViewTable('subContent', 'detail')

        const currentReference = this.allSuppliers.manufacturerBrands.find((brand: any) => brand.name === 'FENOX')
        const allManufacturerBrandsExceptionReference = [...this.allSuppliers.manufacturerBrands.filter((brand: any) => brand.name !== 'FENOX')]

        this.currentSupplierAndBrands = {
          supplier: currentReference,
          brands: allManufacturerBrandsExceptionReference
        }

        this.selectedBrandsForComparisonTable = [
          currentReference,
          ...allManufacturerBrandsExceptionReference
        ]

        this.articlesForDetailTable = [response.data]
      } catch (error) {
        console.log('Error', error)
        if (error.status === 404) {
          this.errorPriceExist = 'Цены по данному артикулу не найдены'
        }
      }
    }

    this.isLoading = false
  }
})
