
import {defineComponent} from "vue";
import prices from "@/services/prices";
import {IDataFile} from "@/models/PricesModel";
import Spinner from "@/components/Spinner.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import PricesFilesTableWrapper from '@/components/prices-list/PricesFilesTableWrapper.vue';

export default defineComponent({
  name: 'PricesSettings',
  components: { PricesFilesTableWrapper, Spinner, BaseIcon },
  data: () => ({
    filesForTable: [] as Array<IDataFile>
  }),
  computed: {
    getLinkForDownloadTemplate() {
      return prices.getTemplateImport()
    }
  },
  methods: {

  },
  async mounted() {
    // this.loading = false
    // this.filesForTable = content
  }
})
