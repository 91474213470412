
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import SearchEntities from '@/components/search/SearchEntities.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import supplier from '@/services/supplier';
import BaseError from '@/components/ui/BaseError.vue';

export default defineComponent({
  name: 'AddSupplierBrandOeCmModal',
  emits: ['setNewSupplierBrand'],
  components: {
    ModalWrapper,
    BaseCheckbox,
    SearchEntities,
    BaseError
  },
  data: () => ({
    error: '' as any,
    directions: [
      {key: 'OE', value: 'OE', checked: true},
      {key: 'CM', value: 'CM', checked: false}
    ],
    result: {
      direction: 'OE',
      brandArt: '',
      brand: {} as any
    },
    searchText: '' as string,
  }),
  props: {
  },
  computed: {
  },
  methods: {
    updateEntity(entity: any) {
      this.result.brand = entity;
      let element = document.getElementById(`modal-search-supplier-brand`)!;
      element.style.display = 'none'
    },
    clickDirection(item: any) {
      this.result.brand = {};
      this.result.direction = item.key;
      this.directions.forEach((o: any) => {
        o.checked = o.key === item.key
      })
    },
    openSearchModal() {
      this.error = ''
      this.searchText = this.result.brand.brand;
      let element = document.getElementById(`modal-search-supplier-brand`)!;
      element.style.display = 'flex'
    },
    closeModal() {
      let element = document.getElementById(`modal-add-supplier-brand`)!;
      element.style.display = 'none'
    },
    async addSupplierBrand() {
      try {
        await supplier.saveAsSupplier({
          brandIds: [this.result.brand.brandId],
          type: 'MANUFACTURER'
        })
        this.$emit('setNewSupplierBrand')
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
        this.closeModal()
      } catch (error) {
        if (error.status === 400) {
          this.error = 'Данный бренд уже добавлен'
        }
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    }
  },
  mounted() {
  }
})
