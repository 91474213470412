import {httpClient} from "@/httpClient";

function importPrice(payload: any) {
  return  httpClient.post(`/prices/import/${localStorage.getItem('cid')}`,
    payload,
    {headers: {'Content-Type': 'multipart/form-data'}})
    .then(response => response.data)
}

async function getPricesAnalyses(payload: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/analyses`, payload).then(response => response.data)
}

async function getPriceAnalysesDetails(payload: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/analyses-details`, payload).then(response => response.data)
}

async function comparisonReport(payload: any) {
  return await httpClient.post(`/prices/import/${localStorage.getItem('cid')}/comparison-report`, payload).then(response => response.data)
}

async function analysesByArticle(articleId: any) {
  return await httpClient.get(`/prices/import/${localStorage.getItem('cid')}/article/${articleId}`)
}


export default {
  importPrice,
  getPricesAnalyses,
  getPriceAnalysesDetails,
  comparisonReport,
  analysesByArticle
}
