import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScatterChart = _resolveComponent("ScatterChart")

  return (_openBlock(), _createBlock(_component_ScatterChart, {
    ref: "lineRef",
    chartData: _ctx.data,
    options: {
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              return `Артикул: ${context.dataset.label} | Цена: ${context.parsed.y} | Дата: ${new Date(context.parsed.x).toLocaleDateString()}`
            }
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            stepSize: _ctx.getStepSize,
            unit: _ctx.rangeMouth >= 6 ? 'month' : 'day'
          }
        },
        y: {
          min: Math.ceil((_ctx.optionsChart.minPrice - 100) / 100) * 100,
          max: Math.ceil(_ctx.optionsChart.maxPrice / 100) * 100
        }
      }
    }
  }, null, 8, ["chartData", "options"]))
}