
import { computed, defineComponent, ref } from 'vue';
import { ExtractComponentData, LineChart, ScatterChart } from 'vue-chart-3';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  ChartData,
  ChartOptions,
  LineOptions,
  registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { ConvertDate } from '@/components/converters/date';
import moment from 'moment';
import GenerateColor from '@/components/utils/GenerateColor';
moment.locale('ru')

Chart.register(...registerables);

export default defineComponent({
  name: 'LineChartView',
  components: { LineChart, ScatterChart },
  props: {
    optionsChart: {
      type: Object,
      required: true
    },
    rangeMouth: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const lineRef = ref<ExtractComponentData<typeof LineChart>>();

    const data = computed(() => ({
      labels: [
        props.optionsChart.labels[0].split('.').reverse().join('.'),
        props.optionsChart.labels[props.optionsChart.labels.length - 1].split('.').reverse().join('.')
      ],
      datasets: [
        ...props.optionsChart.dataLines.map(({ label, data }: any, index: number) => {
          const color = GenerateColor()

          return {
            label,
            showLine: true,
            pointRadius: [3, 1.7, 1.7, 1.7],
            // pointBackgroundColor: [color, 'red', 'red', 'red'],
            backgroundColor: color,
            borderColor: color,
            data: [
              ...data.map((d: any, i: number) => ({
                x: ConvertDate(d.datePrice).split('.').reverse().join('.'),
                y: d.price,
              }))
            ]
          }
        }),
      ],
    }))

    const options = computed(() => ({
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month'
            }
          }
        }
      }
    }))


    const getStepSize = computed(() => {
      if (props.rangeMouth === 1) {
        return 2
      }

      if (props.rangeMouth === 3) {
        return 10
      }

      if (props.rangeMouth === 6) {
        return 1
      }

      if (props.rangeMouth === 12) {
        return 2
      }

    })
    return {
      data,
      options,
      getStepSize
    };
  },
});
