
import {defineComponent} from "vue";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import routerService from "@/services/routerService";
import BaseTable from '@/components/ui/BaseTable.vue'
import TableProductGroupItem from "./TableProductGroupItem.vue";
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';

export default defineComponent({
  name: 'DetailPricesComparisonTableWrapper',
  props: {
    currentSupplierAndBrands: {
      type: Object,
      required: true,
      default: () => null
    },
    articlesForDetailTable: {
      type: Array,
      required: false,
      default: () => null
    },
    selectedBrands: {
      type: Array,
      required: true,
      default: () => null
    },
  },
  components: {
    BaseCheckbox,
    BaseTable,
    TableProductGroupItem,
    DropdownEllipsis
  },
  data: () => ({
    articlesForTable: null as any,
  }),
  computed: {
    productGroupForTable() {
      const uniqProductGroupsNames = Array.from(
        new Set(
          this.articlesForDetailTable
            .flat(1)
            .map((article: any) => article.productGroup)
        )
      ) as any

      const transormedDataForTableByProductGroup = uniqProductGroupsNames.map((productGroupName: string) => {
        let countArrWithProductGroups = 0 // кол-во массивов с текущей товарной группой
        let sumDeviationProductGroupsAndBrand = {} as any // сумма отклонения всех артикулов по товарной группе по определнному бренду
        let allArticlesProductGroup = [] as any // все массивы артикулов с данной товарной группой
        const calcSumDeviationProductGroupsAndBrand = {} as any

        this.articlesForTable.map((arrArticles: any) => {
          if (arrArticles[0].productGroup === productGroupName) { //если первый элемент в массиве совпал с ТГ из уникальных ТГ
            countArrWithProductGroups += 1

            allArticlesProductGroup.push(arrArticles) // добавляем весь массив с артикулами для эталона и других брендов

            const arrSupplierAndSelectedBrands = [
              ...this.currentSupplierAndBrands?.brands,
              this.currentSupplierAndBrands?.supplier
            ]

            arrSupplierAndSelectedBrands.map((brand: any) => { // высчитываем для каждого бренда процент оклонения по товарной группе
              arrArticles.map((article: any) => {
                if (article.supplier === brand.name) {
                  if (typeof article.deviation === 'string') { // проверяем или в отклонения записана строка с несколькими значениями
                    const arrDeviations = article.deviation.split(',').sort((a: number, b: number) => b - a) // строку с отклонения делаем в массив и сортируем от меньшего к большему

                    const middleArrDeviations = arrDeviations.length / 2; // средина массива
                    const deviationMediane = Number(arrDeviations[middleArrDeviations]) // медиана

                    if (sumDeviationProductGroupsAndBrand[brand.name]) {
                      sumDeviationProductGroupsAndBrand[brand.name] += deviationMediane
                    } else {
                      sumDeviationProductGroupsAndBrand[brand.name] = deviationMediane
                    }
                    return;
                  }

                  if (sumDeviationProductGroupsAndBrand[brand.name]) {
                    sumDeviationProductGroupsAndBrand[brand.name] += article.deviation
                  } else {
                    sumDeviationProductGroupsAndBrand[brand.name] = article.deviation
                  }
                }
              })
            })
          }
        })

        const deviationsProductGroups = {} as any

        allArticlesProductGroup.map((arrArticles: any) => {
          arrArticles.map(({supplier, deviation}: any) => {
            if (typeof deviation === 'string') {
              if (deviationsProductGroups[supplier]) {
                deviationsProductGroups[supplier] = [
                  ...deviation.split(',').map((string: any) => +string),
                  ...deviationsProductGroups[supplier]
                ]
              } else {
                deviationsProductGroups[supplier] = [
                  ...deviation.split(',').map((string: any) => +string)
                ]
              }
            } else {
              if (deviationsProductGroups[supplier]) {
                deviationsProductGroups[supplier] = [
                  deviation,
                  ...deviationsProductGroups[supplier]
                ]
              } else {
                deviationsProductGroups[supplier] = [deviation]
              }
            }
          })
        })

        const resultDeviations = {} as any

        Object
          .keys(deviationsProductGroups)
          .map((supplier: string) => {
            const deviationsByBrand = deviationsProductGroups[supplier]


            const median = (arr: Array<number>) => {
              const mid = Math.floor(arr.length / 2)
              const nums = [...arr].sort((a, b) => a - b)

              return arr.length % 2 !== 0
                ? nums[mid]
                : (nums[mid - 1] + nums[mid]) / 2;
            };

            resultDeviations[supplier] = median(deviationsByBrand)
          })

        return {
          deviation: resultDeviations,
          productGroup: productGroupName,
          articles: allArticlesProductGroup,
        }
      })

      return transormedDataForTableByProductGroup
    },
    headerArticles(): any {
      return [
        {
          title: `Артикулы ${this.currentSupplierAndBrands.supplier.name}`,
          content: (comparisonArticle: any) => comparisonArticle[0].article
        },
        ...this.currentSupplierAndBrands.brands.map((brand: any, indexBrandName: number) => ({
          title: brand.name,
          content: (comparisonArticle: any) => {
            const indexComparisonArticleBySupplier = comparisonArticle.findIndex((article: any) => article.supplier === brand.name)
            return comparisonArticle[indexComparisonArticleBySupplier]?.other ?? '-'
          }
        }))
      ]
    },
    headerProductGroup(): any {
      return [
        {
          title: `Товарная группа ${this.currentSupplierAndBrands.supplier.name}`,
          content: (transformedDataProductGroup: any) => transformedDataProductGroup.productGroup,
        },
        ...this.currentSupplierAndBrands.brands.map((brand: any, indexBrandName: number) => ({
          title: brand.name,
          content: (transformedDataProductGroup: any) => {
            return transformedDataProductGroup && transformedDataProductGroup.deviation[brand.name]
                    ? Math.round(transformedDataProductGroup.deviation[brand.name]) + '%'
                    : '-'
          }
        }))
      ]
    }
  },
  methods: {
    setHistory(articles: any) {
      const foundArticleSplice = articles.find((article: any) => article && article.spliceArticle)
      const articlesForHistory = foundArticleSplice
        ? [...articles, ...foundArticleSplice.spliceArticle]
        : articles

      this.$emit('setHistory', articlesForHistory)
    },
    setViewTable(is: any) {
      routerService.setQuery('showTable', is, this.$route.query, this.$router)
    }
  },
  watch: {
    articlesForDetailTable() {
      this.setViewTable('articles')
      this.articlesForTable = this.articlesForDetailTable.map((items: any) => {
        const result = this.selectedBrands.map((brand: any) => {
          const key = brand.name

          const itemWithSameBrandExists = items.findIndex((item: any) => {
            return item.supplier === key
          }) >= 0

          if (itemWithSameBrandExists) {
            let currentItem
            let nextItem

            for (let index = 0; index < items.length; index++) {
              currentItem = items[index]
              nextItem = items[index + 1]

              if (currentItem.supplier === key && nextItem?.supplier === key) {
                if (index === 0) {
                  const currentItemDeviation = typeof currentItem.deviation === 'number'
                    ? currentItem.deviation.toFixed(1)
                    : currentItem.deviation;

                  const nextItemDeviation = nextItem.deviation.toFixed(1);

                  currentItem.deviation = `${currentItemDeviation}, ${nextItemDeviation}`
                } else {
                  currentItem.deviation = `
                  ${currentItem.deviation},
                  ${nextItem.deviation && nextItem.deviation.toFixed(1)}
                `
                }

                currentItem.spliceArticle = items.splice(index + 1, 1);
                return currentItem
              }

              if (currentItem.supplier === key) {
                return currentItem
              }
            }

            return {
              ...currentItem,
              other: '-'
            }
          } else {
           return {
             supplier: key,
             other: '-',
             deviation: 0
           }
          }
        });

        return [
          ...result.map((item: any) => {
            if (item.other) {
              return item
            }

            if (item.standart || item.deviation === -100) {
              return {
                ...item,
                other: item.article
              }
            }

            if (item && !item.price) {
              return {
                ...item,
                other: item.article + '(цена не указана)'
              }
            }

            if (typeof item.deviation === "number") {
              return {
                ...item,
                other: item.deviation.toFixed(1)
              }
            }

            return {
              ...item,
              other: item.deviation
            }
          })
        ]
      })
    }
  }
})
