
import { defineComponent } from 'vue';
import prices from '@/services/prices';
import { ConvertDate } from '@/components/converters/date';
import LineChartView from '@/plugins/Chart/LineChartView.vue';
import GenerateColor from '@/components/utils/GenerateColor';
import timeService from '@/services/timeService';
import Spinner from '@/components/Spinner.vue';
import BaseError from '@/components/ui/BaseError.vue';
import BaseToggleButtons from '@/components/ui/BaseToggleButtons.vue';

export default defineComponent({
  name: 'HistoryPrices',
  components: {
    BaseToggleButtons,
    Spinner,
    LineChartView,
    BaseError
  },
  props: {
    dataArticlesForChart: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    buttonsSettings: [
      {
        name: '1м.',
        value: 1,
        activeColorFont: 'action-active',
        activeBorderColor: 'action-active'
      },
      {
        name: '3м.',
        value: 3,
        activeColorFont: 'action-active',
        activeBorderColor: 'action-active'
      },
      {
        name: '6м.',
        value: 6,
        activeColorFont: 'action-active',
        activeBorderColor: 'action-active'
      },
      {
        name: '1г.',
        value: 12,
        activeColorFont: 'action-active',
        activeBorderColor: 'action-active'
      }
    ],
    rangeMouth: null as number | null,
    isLoading: false as boolean,
    uniqDate: [] as any,
    priceArticlesSortedByDate: {} as any,
    dateShowChart: {
      dateUploadedFrom: new Date().setMonth(new Date().getMonth() - 1),
      dateUploadedTo: new Date(),
    } as any,
    historyArticlesData: null as any,
  }),
  computed: {
    getDataLines(): any {
      return Object
              .keys(this.priceArticlesSortedByDate)
              .map((articleName: string) => ({
                label: `${articleName} (${this.priceArticlesSortedByDate[articleName][0].supplier})`,
                data: this.priceArticlesSortedByDate[articleName].map((article: any) => {
                  return {
                    price: article.price,
                    datePrice: article.fileImport.datePrice
                  }
                }),
                color: GenerateColor()
              }))
    }
  },
  methods: {
    setDateForFewOfMonth(mouth: number) {
      this.rangeMouth = mouth
      const d = new Date()
      d.setMonth(d.getMonth() - mouth)

      this.dateShowChart = {
        dateUploadedFrom: timeService.convertDate(d).split('/').reverse().join('-'),
        dateUploadedTo: timeService.convertDate(this.dateShowChart.dateUploadedTo).split('/').reverse().join('-')
      }

      this.setDataForChart()
    },
    addDays(days: any, parentDate: any) {
      const currentDate = new Date(parentDate.valueOf())
      currentDate.setDate(currentDate.getDate() + days)
      return currentDate
    },
    getDates(startDate: any, stopDate: any) {
      const dateArray = []
      let currentDate = startDate

      while (currentDate <= stopDate) {
        dateArray.push(ConvertDate(currentDate))
        currentDate = this.addDays(1, currentDate)
      }
      return dateArray;
    },
    async setDataForChart() {
      this.uniqDate = []
      this.priceArticlesSortedByDate = {}

      if (this.dataArticlesForChart) {
        this.isLoading = true

        this.historyArticlesData = await prices.search({ // получаем историю по выбранным артикулам
          articles: [
            ...this.dataArticlesForChart.map((article: any) => article.article)
          ],
          ...(this.dateShowChart && this.dateShowChart.dateUploadedFrom && {
            dateUploadedFrom: this.dateShowChart.dateUploadedFrom,
            dateUploadedTo: this.dateShowChart.dateUploadedTo
          }),
        })

        const articlesSortedByDate = this.historyArticlesData.content.sort((a: any, b: any) => { //сортируем по дате, чтобы отрисовать график от меньшего к большему
          return Number(new Date(a.fileImport.datePrice)) - Number(new Date(b.fileImport.datePrice));
        });

        const allNameArticles = articlesSortedByDate.map((article: any) => article.article)
        const uniqNameArticles = new Set(allNameArticles) as any

        this.uniqDate = this.getDates(
          new Date(this.dateShowChart.dateUploadedFrom),
          new Date(this.dateShowChart.dateUploadedTo),
        )

        this.uniqDate.map((date: any) => {
          const filteredArticlesByDate = articlesSortedByDate.filter((article: any) => { // фильтруем артикулы по текущей дате, чтобы правильно распределить по дата и соотвественно отрисовать
            return ConvertDate(article.fileImport.datePrice) === date
          })

          const namesArticles = [...uniqNameArticles]

          namesArticles.map((articleName: string) => {
            const foundArticle = filteredArticlesByDate.find((article: any) => article.article === articleName)

            if (foundArticle) {
              this.priceArticlesSortedByDate = { // сохраняем данные в виде {[Название Артикула]: [данные в соотвествии с текущей датой]}
                ...this.priceArticlesSortedByDate,
                [foundArticle.article]: [
                  ...(date && this.priceArticlesSortedByDate[foundArticle.article] ? this.priceArticlesSortedByDate[foundArticle.article] : []), // сохраняем данные, если они есть
                  foundArticle,
                ]
              }
            } else {
              if (!(this.priceArticlesSortedByDate[articleName] && this.priceArticlesSortedByDate[articleName].length)) {
                const fA = articlesSortedByDate.find((article: any) => article.article === articleName)

                this.priceArticlesSortedByDate = { // сохраняем данные в виде {[Название Артикула]: [данные в соотвествии с текущей датой]}
                  ...this.priceArticlesSortedByDate,
                  [articleName]: [
                    {
                      ...fA,
                      fileImport: {
                        ...fA.fileImport,
                        datePrice: new Date(this.dateShowChart.dateUploadedFrom)
                      }
                    },
                  ]
                }
              } else {
                const dataArticlesByArticleName = this.priceArticlesSortedByDate[articleName]
                const r = dataArticlesByArticleName[dataArticlesByArticleName.length - 1]

                this.priceArticlesSortedByDate = { // сохраняем данные в виде {[Название Артикула]: [данные в соотвествии с текущей датой]}
                  ...this.priceArticlesSortedByDate,
                  [articleName]: [
                    ...(date && this.priceArticlesSortedByDate[articleName] ? this.priceArticlesSortedByDate[articleName] : []), // сохраняем данные, если они есть
                    {
                      ...r,
                      fileImport: {
                        ...r.fileImport,
                        datePrice: this.addDays(1, new Date(r.fileImport.datePrice))
                      }
                    },
                  ]
                }
              }
            }
          })
        })

        this.isLoading = false
      }
    }
  },
  watch: {
    dataArticlesForChart() { // делаем watch, потому что на компоненте висит v-show
      this.setDateForFewOfMonth(3)
    }
  },
})
