import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")

  return (_openBlock(), _createBlock(_component_DoughnutChart, {
    ref: "doughnutRef",
    chartData: _ctx.testData,
    options: _ctx.options,
    width: 300,
    height: 300
  }, null, 8, ["chartData", "options"]))
}