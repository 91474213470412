
import {defineComponent} from "vue";
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';

export default defineComponent({
  name: 'TableProductGroupItem',
  components: {
    IconArrowLeft
  },
  data: () => ({
    isOpenProductGroup: false as boolean,
  }),
  props: {
    isListOpen: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isShowArrow: {
      type: Boolean,
      required: false,
      default: () => true
    },
    productGroupArticles: {
      type: Array,
      required: true,
      default: () => null
    },
    itemText: {
      type: String,
      required: true,
      default: () => null
    },
  },
})
