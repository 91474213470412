
import { defineComponent, HTMLAttributes } from 'vue';
import ModalWrapper from "@/components/modals/ModalWrapper.vue";
import routerService from "@/services/routerService";
import supplier from "@/services/supplier";
import priceImport from "@/services/priceImport";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import {DatePicker} from "v-calendar";
import Spinner from "@/components/Spinner.vue";
import BaseError from '@/components/ui/BaseError.vue';

export default defineComponent({
  name: 'PriceLoadModal',
  components: {
    ModalWrapper,
    DatePicker,
    Spinner,
    BaseError
  },
  data: () => ({
    types: null as any,
    typePrices: {
      'MANUFACTURER': 'Прайсы производителей',
      'DISTRIBUTOR': 'Прайсы дистрибьюторов'
    } as any,
    percent: null as any,
    isPickedType: false as boolean,
    range: new Date() as any,
    pickedIdType: null as any,
    isLoading: false,
    sendErrorFileMessage: null as any,
    file: null as any,
  }),
  computed: {
    getPriceName() {
      return (keyPrice: any) => this.typePrices[keyPrice]
    }
  },
  methods: {
    clearState() {
      this.percent = null
      this.pickedIdType = null
      this.types = null
      this.range = null
      this.file = null
    },
    closeModal() {
      let element = document.getElementById(`modal-price-load`)!;
      element.style.display = 'none';

      routerService.clearQueries(['typePrice', 'supplier'], this.$route.query, this.$router)
      this.clearState()
    },
    setFile(e: any) {
      this.file = e.target.files || e.dataTransfer.files;
    },
    async getAllTypeByName(typePrice: string) {
      routerService.setQuery('typePrice', typePrice, this.$route.query, this.$router)
      supplier.getAllByType(typePrice).then((res: any) => {
        this.types = res.map((pb: any) => {
          if (pb.id === 10) {
            return {
              ...pb,
              name: 'FENOX'
            }
          } else {
            return pb
          }
        })
      })
    },
    setData(id: any) {
      routerService.setQuery('supplier', id, this.$route.query, this.$router)
      this.isPickedType = true
      this.pickedIdType = id
    },
    async sendData() {
      let formData = new FormData() as any;
      let date = this.range

      let year = String(date.getFullYear());
      let month = date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : String(date.getMonth() + 1);
      let day = date.getDate() < 10 ? '0' + String(date.getDate()) : String(date.getDate());
      let hours = date.getHours() < 10 ? '0' + String(date.getHours()) : String(date.getHours());
      let minutes = date.getMinutes() < 10 ? '0' + String(date.getMinutes()) : String(date.getMinutes());
      let seconds = date.getSeconds() < 10 ? '0' + String(date.getSeconds()) : String(date.getSeconds());

      formData.append('file', this.file[0]);
      formData.append('supplierId', this.pickedIdType);
      formData.append('percent', this.percent);
      formData.append('datePrice', `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);

      this.isLoading = true
      try {
        await priceImport.importPrice(formData)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
        this.sendErrorFileMessage = error.data.message
        this.isLoading = false
        throw error
      }

      this.isLoading = false
      this.closeModal()
    },
  }
})
