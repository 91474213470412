
import { computed, defineComponent, ref } from 'vue';
import { DoughnutChart, ExtractComponentData } from 'vue-chart-3';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  ChartData,
  ChartOptions,
  registerables
} from 'chart.js';
import GenerateColor from '@/components/utils/GenerateColor';

Chart.register(...registerables);

export default defineComponent({
  name: 'DoughnutChartView',
  emits: ['clickLegend'],
  components: { DoughnutChart },
  props: {
    optionsChart: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}) {
    const doughnutRef = ref<ExtractComponentData<typeof DoughnutChart>>();
    function clickLegend(context: any, event: any) {
      emit('clickLegend', event.text, props.optionsChart.title)
    }

    const testData = computed<ChartData<'doughnut'>>(() => ({
      labels: props.optionsChart.labels,
      datasets: [
        {
          data: props.optionsChart.numbersComparison,
          backgroundColor: new Array(10).fill(' ').map(() => GenerateColor()),
        },
      ],
    })) as any;

    const options = ref<ChartOptions<'doughnut'>>({
      responsive: true,
      plugins: {
        legend: {
          position: props.optionsChart.legendPosition || 'top',
          onClick: clickLegend,
          onHover: (event: any, legendItem: any, legend: any) => {
            const e = event!
            const $event = event as any
            $event.native.target.style.cursor = 'pointer'
          }
        },
        title: {
          display: true,
          text: props.optionsChart.title,
          font: {
            size: 15
          }
        },
      },
      onClick(event: any, element: any, legend: any) {
        var ind = element[0].index;
        clickLegend(event, {text: testData.value.labels[ind]})
      },
    });

    return { testData, doughnutRef, options };
  },
});
